<template>
  <div class="service-times-presentations template-2">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcMemberDetails }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </header>
    <page-body class="mx-lg" v-if="individualProfile">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ indDisplayName }}</h4>
          </header>
          <div class="d-flex row mb-2 details2">
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcMemberId }}: </strong>
                <span>{{ individualProfile.member_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcMemberSince }}: </strong>
                <span>{{ formatDate(individualProfile.join_date) }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcCampNumber }}: </strong>
                <span>{{ individualProfile.camp_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcCampName }}: </strong>
                <span>{{ individualProfile.camp_name }}</span>
              </div>
              <div class="detail" v-if="church_membership_accessible">
                <strong>{{ translations.tcChurchMembership }}: </strong>
                <span>{{ individualProfile.church_name }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcDenomination }}: </strong>
                <span>{{ individualProfile.denomination }}</span>
              </div>
              <div class="detail" v-if="individualProfile.member_type.includes('Gideon')">
                <strong>{{ translations.tcOccupation }}: </strong>
                <span>{{ individualProfile.occupation }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail" v-if="individualProfile.member_type.includes('Gideon')">
                <strong>{{ translations.tcChurchSpeaker }}: </strong>
                <span>
                  {{ translations.tcQualified }}:
                  {{ individualProfile.cp_qualified_flag ? translations.tcYes || 'Yes' : translations.tcNo || 'No' }}
                </span>
                <span> {{ translations.tcDate }}: {{ formatDate(individualProfile.cp_qualified_date) }}</span>
                <span>
                  {{ translations.tcMedia }}:
                  {{ this.individualProfile.cp_media_flag ? translations.tcYes || 'Yes' : translations.tcNo || 'No' }}
                </span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcPastorsEventSpeaker }}: </strong>
                <span>
                  {{ translations.tcQualified }}:
                  {{ individualProfile.bs_qualified_flag ? translations.tcYes || 'Yes' : translations.tcNo || 'No' }}
                </span>
                <span>
                  {{ translations.tcDate }}:
                  {{ formatDate(individualProfile.bs_qualified_date) }}
                </span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcMembershipDinnerSpeaker }}: </strong>
                <span>
                  {{ translations.tcQualified }}:
                  {{ individualProfile.ds_qualified_flag ? translations.tcYes || 'Yes' : translations.tcNo || 'No' }}
                </span>
                <span>
                  {{ translations.tcDate }}:
                  {{ formatDate(individualProfile.ds_qualified_date) }}
                </span>
              </div>
              <div class="detail" v-if="individualProfile.member_type.includes('Gideon')">
                <strong>{{ translations.tcScriptureBlitzParticipant }}: </strong>
                <span>
                  {{ !!individualProfile.scripture_blitz_flag ? translations.tcYes || 'Yes' : translations.tcNo || 'No'
                  }}
                </span>
              </div>
            </div>
            <div class="col">
              <div class="detail full-detail">
                <strong>{{ translations.tcOtherInformation }}: </strong>
                <span v-html="individualProfile.comment"></span>
              </div>
            </div>
          </div>
          <b-button v-if="toggleMe && (okToEditProfile || edit_accessible)" variant="primary" @click="handleEditClick"
            class="">
            {{ translations.tcEdit }}
          </b-button>
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg mt-5">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'member-details',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      edit_accessible: false,
      church_membership_accessible: false,
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/mb/member-profile',
        location: '',
      },
      secured_controls: {
        view_member_church_membership_link: '60a420bc-1b3c-458c-b686-7b9338f2b041',
        edit_member_profile_details_button: 'c2c24c36-43b0-4e9d-a49f-0f68d3aea038',
      },
    }
  },
  methods: {
    ...mapActions({
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.checkPermissions(),
          this.getViewTranslations(),
          this.getComponentTranslations('back-jump'),
          this.getComponentTranslations('common.occupation-type'),
          await this.loadIndividualProfile(),
          (this.backJumpObj.location = this.indDisplayName),
        ]).then(results => {
          this.stripReadableText(results[3])
          const translatedText = {
            ...results[2],
            common: { ...this.translations.common }
          }
          this.$set(this.translations, 'components', translatedText)
          this.setTranslatedOccupationName()
        })
      } catch (e) {
        console.error('Error in MemberDetails.vue, pageLoad(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }

    },
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
    async handleEditClick() {
      this.$router.push({ name: 'individual-details-edit' })
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.edit_accessible = this.determineAccessibility(
          this.secured_controls.edit_member_profile_details_button,
          true
        )
        this.church_membership_accessible = this.determineAccessibility(
          this.secured_controls.view_member_church_membership_link
        )
      })
    },
  },
  computed: {
    ...mapGetters({
      individualProfile: 'membership/individualProfile',
      officerToolbarSelected: 'user/officerToolbarSelected',
      okToEditProfile: 'user/okToEditProfile',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    indDisplayName() {
      return !!this.individualProfile.informal_name && this.individualProfile.preferred_name === 'I' ? `${this.individualProfile.prefix} ${this.individualProfile.informal_name} ${this.individualProfile.last_name}` : this.individualProfile.formal_name
    },
  },
  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ name: 'membership' })
    }
    await this.pageLoad()
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details2 {
  margin-bottom: 50px;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .detail {
    font-size: 15px;
    margin-bottom: 10px;

    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
    }

    strong {
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }

    &.full-detail {
      display: block;
    }
  }
}
</style>
